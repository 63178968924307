 
@import "../../styles/_variables.scss";

.header {
	background-color: #212121;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
	
    &__enter {
		height: 80px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		box-sizing:border-box;
		align-items: center;
	}

	&__navbar {
		display: flex;
		flex-direction: row;
	}
}

.header-logo {
	fill: #ffffff;
	display: flex;
	align-items: center;
	text-decoration: none;

	&__caption{
		display: flex;
		align-items: center;
		font-weight: 500;

		height: 24px;
		background: #414141;
		border-radius: 30px;
		color: #ffffff;
		padding-left: 8px;
		padding-right: 8px;
		margin-left: 12px;

		transition: padding 0.3s ease 0s;
	}

	&:hover{
		.header-logo__caption{
			padding-left: 16px;
			padding-right: 16px;
		}
	}
}

.header-sidebar {
	display: flex;
	align-items: center;
}



