@import '../../styles/_variables.scss';

.file-button{
    position: relative;

    &__input {
		top: 0;
		left: 0;
		width: 100%;
		cursor: inherit;
		height: 100%;
		margin: 0;
		opacity: 0;
		padding: 0;
		z-index: 1;
		position: absolute;
	}
}

.step-number {
	background-color: #414141;
	color: #fff;
	display: flex;
	height: 16px;
	width: 16px;
	align-items: center;
	justify-content: center;
    margin-top: 4px;
    margin-bottom: 4px;
}

.card-information {
	display: flex;
	justify-content: space-between;
}