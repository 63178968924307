@import "./_variables.scss";

.flex-row {
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	margin-right: -8px;
	margin-left: -8px;
}	

.flex-column{
	box-sizing: border-box;
	position: relative;
	width: 100%;
	padding-right: 8px;
	padding-left: 8px;
}

.flex-column {
	@extend .flex-column;
	flex-grow: 1;
	flex-basis: 0;
	max-width: 100%;
}

.flex-column-1 {
	@extend .flex-column;
	flex-basis: 8.33333333%;
	max-width: 8.33333333%;
}

.flex-column-2 {
	@extend .flex-column;
	flex-basis: 16.66666667%;
	max-width: 16.66666667%;
}

.flex-column-3 {
	@extend .flex-column;
	flex-basis: 25%;
	max-width: 25%;
}

.flex-column-4 {
	@extend .flex-column;
	flex-basis: 33.33333333%;
	max-width: 33.33333333%;
}

.flex-column-5 {
	@extend .flex-column;
	flex-basis: 41.66666667%;
	max-width: 41.66666667%;
}

.flex-column-6 {
	@extend .flex-column;
	flex-basis: 50%;
	max-width: 50%;
}

.flex-column-7 {
	@extend .flex-column;
	flex-basis: 58.33333333%;
	max-width: 58.33333333%;
}

.flex-column-8 {
	@extend .flex-column;
	flex-basis: 66.66666667%;
	max-width: 66.66666667%;
}

.flex-column-9 {
	@extend .flex-column;
	flex-basis: 75%;
	max-width: 75%;
}

.flex-column-10 {
	@extend .flex-column;
	flex-basis: 83.33333333%;
	max-width: 83.33333333%;
}

.flex-column-11 {
	@extend .flex-column;
	flex-basis: 91.66666667%;
	max-width: 91.66666667%;
}

.flex-column-12 {
	@extend .flex-column;
	flex-basis: 100%;
	max-width: 100%;
}