@import '../../styles/_variables.scss';

.button {
	box-sizing: border-box;
	text-decoration: none;
	user-select: none;
	border: none;

	display: flex;
	justify-content: center;
	align-items: center;

	width: auto;
	height: auto;

	background-color: #ffffff;
	cursor: pointer;

	font-family: inherit;
	font-style: normal;
	font-size: 14px;
	color: #ffffff;

	text-align: center;
	white-space: nowrap;
	vertical-align: middle;

	transition: background-color 300ms, color 300ms, box-shadow 200ms;

	height: 32px;
	padding-left: 16px;
	padding-right: 16px;
	border-radius: 4px;
	font-weight: 500;

	
	&:focus,
	&.focus {
		outline: 0;
		box-shadow: none;
	}

	&.disabled,
	&[disabled] {
		pointer-events: none;
		background-color: #c7c7c7;
		color: #ffffff;
	}

	&:hover,
	&.is-active {
		box-shadow: 0 110px 0 0 rgba(0, 0, 0, 0.1) inset;
	}

	&--busy {
		position: relative;
		pointer-events: none;

		&::after {
			content: '';
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background-image: linear-gradient(
				-45deg,
				rgba(255, 255, 255, 0.2) 25%,
				transparent 25%,
				transparent 50%,
				rgba(255, 255, 255, 0.2) 50%,
				rgba(255, 255, 255, 0.2) 75%,
				transparent 75%,
				transparent
			);
			background-size: 35px 35px;
			animation: animationProgress 2s linear infinite;
		}
	}

	@keyframes animationProgress {
		0% {
			background-position: 0 0;
		}
		100% {
			background-position: 35px 35px;
		}
	}
}

.button-primary {
	background: #FFAA1E;
}

.button-secondary {
	background: #8dca0f;
}
