@import "../../styles/_variables.scss";

.user-menu {
	position: relative;
	height: 50px;
	width: 50px;
	cursor: pointer;

	&:hover {
		.user-menu-item {
			width: 200px;
		
			@for $i from 1 through 2 {
				$translateY: $i * 60;
				&:nth-of-type(#{$i}) {
					transform: translateY(#{$translateY}px);
				}
			}
		}
	}
}

.user-menu-initials {
	display: flex;
	position: absolute;
	width: 50px;
	height: 50px;
	right: 0;
	top: 0;
	background-color: #ffaa1e;
	box-sizing: border-box;
	color: #ffffff;

	font-size: 24px;
	font-weight: 500;
	z-index: 100;
	border-radius: 30px;

	justify-content: center;
	align-items: center;
	z-index: 10;
	backface-visibility: hidden;
}

.user-menu-item {
	position: absolute;
	width: 50px;
	height: 50px;
	right: 0;
	top: -10px;
	background-color: transparent;
	transform: translateY(0);

	&:nth-of-type(1) {
		z-index: 8;
		transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.01s;
	}

	&:nth-of-type(2) {
		z-index: 9;
		transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 15ms;
	}

	&__link {
		position: relative;
		top: 10px;
		display: block;
		height: 50px;
		width: 50px;
		border-radius: 30px;
		background-color:  #ffaa1e;
		float: right;
		transition: width 0.25s ease-in-out 0s, box-shadow 0.2s ease-in-out 0s;
		color: #ffffff;
		text-decoration: none;
		//box-shadow: 0 0 0 5px #c7c7c7 inset;
		&:hover {
			width: 100%;
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

			.user-menu-item__caption {
				opacity: 1;
				visibility: visible;
				transition: all 0.1s ease 0.2s;
			}

			.icon {
				transform: translateY(-50%) rotate(360deg);
				transition: all 0.4s ease 0.1s;
			}
		}
	}

	&__caption {
		font-size: 16px;
		font-weight: 500;
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
		white-space: nowrap;

		transition: all 0.1s ease 0s;
		opacity: 0;
		visibility: hidden;
	}

	.icon {
		width: 24px;
		height: 24px;
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%) rotate(0deg);
		transition: none;

		& + .user-menu-item__caption{
			right: 45px;
		}
	}

}