@import "./_variables.scss";

.table{
	box-sizing: border-box;
	width: 100%;
	max-width: 100%;

	border-spacing: 0;
	border-collapse: separate;
	background-color: transparent;

	tr{
		&:hover{
			.table-cell{
				background-color: #E9F7FC;
			}
		}
	}
} 

.table-head-cell{
	text-align: center;
	vertical-align: middle;

	font-family: inherit;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: #333333;
	padding: 12px 5px;
	background-color: #F1F5F9;
}


.table-cell{
	text-align: center;
	vertical-align: middle;
	padding: 4px 6px;

	font-size: 14px;
	line-height: 18px;
	font-family: inherit;

	background-color: transparent;
	transition: background .2s ease 0s;

	border-bottom: 1px solid #E0E0E0;
	color: #4F4F4F;
}