@import '../../styles/_variables.scss';


.table-surveys{

	.table-head-cell{
		text-align: left;
	}

	.table-cell{
		text-align: left;
	}

	.cell-id{
		padding-left: 15px;
		text-align: left;
	}

	.cell-status{
		&--closed{
			color:#17AD44;
		}
		&--draft{
			color:#000000; 
		}
		&--live{
			color:#ff4545; 
		}
	}

	.cell-actions{
		text-align: center;

		.button {
			display: inline-flex;
		}
	}
}
