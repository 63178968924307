 
@import "../../styles/_variables.scss";

.container-header {
	background-color: #2E343D;
	color: #ffffff;
	box-sizing: border-box;
	border-radius: 4px;
	padding-left: 16px;
	padding-right: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 56px;

	&__caption{
		font-size: 24px;
		color: #ffffff;
	}
}