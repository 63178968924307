@import '../styles/_variables.scss';

* {
	box-sizing: border-box;
	outline: none;
}

html,
body {
	margin: 0;
	padding: 0;
}

img {
	max-width: 100%;
}

a {
	color: #FFC107;
	text-decoration: none;
	
	&:active,
	&:hover,
	&:focus {
		outline: none;
	}
}


body {
	font-family: 'Work Sans', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: rgb(20, 25, 50);

	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;

	overflow-y: scroll;
	overflow-x: hidden;
}

.wrapper {
	margin: 0 auto;
	min-width: 1024px;
	max-width: 1140px;
	padding-left: 16px;
	padding-right: 16px;
}