@import "../../styles/_variables.scss";

$light-color: #E0E3E8;
$dark-color: #DBDEE3;
$background-color: #f9f9f9;

 
@keyframes content-loader-animate {
	0% {
		transform: translateX(-100%) translateZ(0);
	}
	100% {
		transform: translateX(100%) translateZ(0);
	}
}

@keyframes curtain-char-animate {
	0% {
		opacity: 0;
		transform: translateX(-50px);
	}

	15% {
		opacity: 0;
		transform: translateX(-50px);
	}

	30% {
		opacity: 1;
		transform: translateX(0);
	}

	70% {
		opacity: 1;
		transform: translateX(0);
	}

	85% {
		opacity: 0;
		transform: translateX(50px);
	}

	100% {
		opacity: 0;
		transform: translateX(50px);
	}
}


.content-loader {
	overflow: hidden;
}

.content-loader-block {
	height: 15px;
	margin-top: 10px;
	background-color: $dark-color;
	position: relative;

	&:before {
		content: '';
		background-color: $dark-color;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		animation-duration: 1s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: content-loader-animate;
		animation-timing-function: linear;
		background: linear-gradient(
			to right,
			$dark-color 8%,
			$light-color 18%,
			$dark-color 33%
		);

	}

	&:after {
		content: '';
		position: absolute;
		background-color: $background-color;
		right: 0;
		top: 0;
		height: 100%;
		width: 0;

	}

	&--one {
		margin-top: 0;
		&:after {
			width: 15%;
		}
	}

	&--two {
		&:after {
			width: 1%;
		}
	}

	&--three {
		&:after {
			width: 28%;
		}
	}

	&--four {
		&:after {
			width: 10%;
		}
	}

	&--five {
		&:after {
			width: 60%;
		}
	}
}


.curtain-loader{
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAEElEQVQImWNgQAJNDAwMDAACnACD0/ko6gAAAABJRU5ErkJggg==);
	z-index: 900;
}

.curtain-loader-word{
	left: 50%;
	top: 50%;
	position: absolute;
	transform: translateX(-50%) translateY(-100%);
	z-index: 999;
	transition: all 0.3s ease 0s;
	width: 120px;
	margin-left: -30px;
	height: 30px;
	pointer-events: none;

	&__char {
		display: block;
		opacity: 0;
		position: absolute;
		color: #3c3c3c;
		font-size: 24px;
		text-shadow: rgba(255,255,255,0.5) 0px 3px 3px;
		line-height: 30px;
		width: 30px;
		text-align: center;

		animation: curtain-char-animate 2s ease infinite;

		@for $i from 1 through 5 {
			$delay: $i*0.1;
			&:nth-child(#{$i}) {
				animation-delay: #{$delay}s;
 				left: #{$i*30}px;
				top: 0px;
			}
		}
	}
}
